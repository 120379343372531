<template>
    <div>
        <table class="layui-table">
            <thead>
            <tr>
                <th style="width: 10%">通知大类</th>
                <th style="width: 15%">通知小类</th>
                <th style="width: 15%">模板名称</th>
                <th style="width: 30%">模板配置消息</th>
                <th style="width: 30%">示例消息</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item,key) in Moban_list_group_list">
                <template v-for="(iitem,kkey) in item.notice_type_list">
                    <template v-if="iitem.moban_list.length>0" v-for="(iiitem,kkkey) in iitem.moban_list">
                        <tr>
                            <td v-if="iiitem.rowspan_index===1" :rowspan="item.rowspan">{{ item.name }}</td>
                            <td v-if="kkkey===0" :rowspan="iitem.rowspan">{{ iitem.name }}</td>
                            <td>{{ iiitem.moban_name }}</td>
                            <td>{{ iiitem.moban_content }}</td>
                            <td>{{ iiitem.moban_show }}</td>
                        </tr>
                    </template>
                    <template v-if="iitem.moban_list.length===0">
                        <tr>
                            <td>{{ iitem.name }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </template>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "mubanlist",
        data() {
            return {
                Moban_list_group_list: []
            }
        },
        mounted() {
            this.Moban_list_group()
        },
        methods: {

            Moban_list_group() {
                this.$sa0.post({
                    url: this.$api('Moban_list_group'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Moban_list_group_list = response.data.list.map(item => {
                                item.rowspan = 0;
                                let rowspan_index=1;
                                item.notice_type_list = item.notice_type_list.map(item_notice_type_list => {
                                    if(item_notice_type_list.moban_list.length==0){
                                        item.rowspan += 1
                                        item_notice_type_list.rowspan = 0
                                    }else{
                                        item.rowspan += item_notice_type_list.moban_list.length
                                        item_notice_type_list.rowspan = item_notice_type_list.moban_list.length
                                    }
                                    item_notice_type_list.moban_list=item_notice_type_list.moban_list.map(item_moban_list=>{
                                        item_moban_list.rowspan_index=rowspan_index
                                        rowspan_index=0
                                        return item_moban_list
                                    })
                                    return item_notice_type_list
                                })
                                return item
                            });
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
        }
    }
</script>

<style scoped>
    table.second {
        width: 100%;
        border: none !important;
    }

    table.second td {
        border: none !important;
    }

    table.second tr:last-child td {
        border-bottom: none !important;
    }


    table.notice_type_list {
        width: 100%;
        border: none !important;
    }

    table.notice_type_list td {
        border-top: none !important;
        border-left: none !important;
        border-right: solid 1px #eee !important;
        border-bottom: solid 1px #eee !important;
    }

    table.moban_list {
        width: 100%;
        border: none !important;
    }

    table.moban_list td {
        border-right: solid 1px #eee !important;
        border-bottom: solid 1px #eee !important;
    }

    table.moban_list tr td:last-child {
        border-right: none !important;
    }

    table.moban_list tr:last-child td {
        border-bottom: none !important;
    }
</style>
